import React from 'react'
//import hoja3dep from '../img/rsz_dep450-hoja3.png'
import hoja3dep from '../img/rsz_dep450-hoja3.webp'

const Info3DEP4503 = () => {
  return (
    <div className="info-foto3">
      <img src={hoja3dep} width="100%" alt="Información pág3 radio dep450"></img>
    </div>
  )
}

export default Info3DEP4503
