import React from 'react'
//import hoja2 from '../img/EP450-hoja2.png'
import hoja2 from '../img/EP450-hoja2.webp'

const Info2EP450 = () => {
  return (
    <div className="info-foto">
      <img src={hoja2} width="77.5%" alt="Información radio ep450"></img>
    </div>
  )
}

export default Info2EP450
