import React from 'react'
//import hoja2dep from '../img/rsz_dep450-hoja2-5.png'
import hoja2dep from '../img/rsz_dep450-hoja2-5.webp'

const Info2DEP4503 = () => {
  return (
    <div className="info-foto3">
      <img src={hoja2dep} width="100%" alt="Información pág2 radio dep"></img>
    </div>
  )
}

export default Info2DEP4503
