import React from 'react'
//import hoja4dep from '../img/DEP450-hoja4.png'
import hoja4dep from '../img/DEP450-hoja4.webp'

const Info4DEP450 = () => {
  return (
    <div className="info-foto">
      <img src={hoja4dep} width="77.5%" alt="Información pág4 radio dep450"></img>
    </div>
  )
}

export default Info4DEP450
